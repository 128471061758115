@import "../../styles/variables.scss";

.infoContainer {
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 50px auto;
  width: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 1rem;
  margin: 1rem 0 1rem 0;
  padding: 0 16px 0 16px;

  & p {
    text-align: left;

  }
}

//Icon Wrapper
.iconWrapper {
  width: 50px;
  height: 50px;
  background-color: $teal;
  border-radius: 100%;
  position: relative;
  margin-bottom: 1rem;
}

.infoIcon {
  font-size: 2rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modalButton {
  width: 90%;
  margin: 0 auto;
}

.welcomeCloseButton {
  font-size: 2rem;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.terms {
  >div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }

  padding: 0 16px 0 16px;
  margin-bottom: 10px;

  input {
    margin-right: 10px;
    height: 20px;
    width: 20px;
    accent-color: $teal;
  }

  p {
    color: red;
  }

  a {
    text-decoration: underline;
  }
}